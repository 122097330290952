import { useLastUsedVersion, useVersionCheck } from 'hooks/updates';
import { useWindowDestroyer } from 'hooks/window';
import * as React from 'react';
import { createUseStyles } from 'react-jss';
import UseScrollbarStyles from 'styles/ScrollbarStyles';

import Button, { ButtonType } from '../basic/Button';
import Release0202 from './Release0202';
import Release0203 from './Release0203';
import Release0300 from './Release0300';
import Release0310 from './Release0310';

const useStyles = createUseStyles({
  header: {
    fontSize: 'large',
    fontWeight: 'bold'
  },
  window: {
    display: 'flex',
    flexDirection: 'column'
  },
  updates: {
    overflowY: 'scroll',
    flex: '1 1 auto'
  },
  footer: {
    flex: 'auto'
  },
  close_button: {
    float: 'right',
    margin: '10px 0 -5px 0',
    padding: '0 10px'
  }
});

export interface UpdatesWindowProps {
  className?: string;
  window_id: string;
}

const UpdatesWindow = ({ className, window_id }: UpdatesWindowProps) => {
  const styles = useStyles();
  const scrollbarStyles = UseScrollbarStyles();
  const update_last_used_version = useLastUsedVersion()[1];
  const destroy_window = useWindowDestroyer();
  const { current_version, last_used_version } = useVersionCheck();

  const close_label = current_version.is_greater_than(last_used_version)
    ? 'Hide Until Next Update'
    : 'Close';

  return (
    <div className={styles.window}>
      <div className={`${styles.updates} ${scrollbarStyles.thin_scrollbar}`}>
        <div className={styles.header}>Update 3: Futures Rewritten</div>
        <Release0310 />
        <Release0300 />
        <Release0203 />
        <Release0202 />
        <hr />
      </div>
      <div className={styles.footer}>
        <Button
          className={styles.close_button}
          type={ButtonType.Text}
          on_click={() => {
            update_last_used_version();
            destroy_window(window_id);
          }}
        >
          {close_label}
        </Button>
      </div>
    </div>
  );
};
export default UpdatesWindow;
