import * as React from 'react';

import Button, { ButtonType } from 'components/basic/Button';
import UseFormStyles from 'styles/FormStyles';
import { usePostCountOption, usePostCountTemplateOption } from 'hooks/options';

export interface PostCountOptionProps {
}

const PostCountOption = (props: PostCountOptionProps): React.ReactElement => {
  const [is_visible, set_is_visible] = usePostCountOption();
  const [template, set_template] = usePostCountTemplateOption();
  const styles = UseFormStyles();

  const checkbox_style = is_visible ? styles.checked : styles.unchecked;

  return (
    <>
      <Button
        type={ButtonType.FlatText}
        on_click={() => set_is_visible(!is_visible)}
      >
        <div className={`${styles.checkbox} ${checkbox_style}`}></div>
        Include split post count in text by default
      </Button>
      {
        is_visible &&
        <div>
          <div>
            <span className={styles.pad}></span>
            <span className={styles.pad}></span>
            <span className={styles.pad}></span>
            Post count template:
            <span className={styles.pad}></span>
            <span className={styles.pad}></span>
            <input
              className={styles.textbox}
              value={template}
              onChange={(e) => set_template(e.target.value)}
            />
          </div>
          <div>
            <span className={styles.pad}></span>
            <span className={styles.pad}></span>
            <span className={styles.pad}></span>
            <strong>[[c]]</strong> - current post
          </div>
          <div>
            <span className={styles.pad}></span>
            <span className={styles.pad}></span>
            <span className={styles.pad}></span>
            <strong>[[t]]</strong> - total number of posts
          </div>
        </div>
      }
    </>
  );
};

export default PostCountOption;
