import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { iRootState, StoreDispatch } from 'redux/store';
import OptionsWindow from 'components/OptionsWindow';
import { useLocalStorage } from './storage';
import { ChatChannel } from 'models/roleplay';
import { useWindowCreator, useWindowDestroyer } from './window';
import { ActiveWindow, WindowSection } from 'models/window';
import { Icons } from 'icons';

const keys = {
  desktop: {
    is_eorzean_clock_visible: 'is_eorzean_clock_visible',
    is_local_clock_visible: 'is_local_clock_visible',
    is_server_clock_visible: 'is_server_clock_visible'
  },
  roleplay: {
    show_post_count: 'show_post_count',
    post_count_template: 'post_count_template',
    default_chat_channel: 'default_chat_channel',
    confirm_on_clear: 'confirm_on_clear'
  },
  parser: {
    max_post_length: 'max_post_length'
  }
};

export const useOptionsWindow = (): (() => void) => {
  const create_window = useWindowCreator();
  const destroy_window = useWindowDestroyer();
  const new_window_id = 'options_window';

  return () => {
    let window: ActiveWindow = {
      title: 'Options',
      icon_path: Icons.Options,
      window_id: new_window_id,
      section: WindowSection.Unsectioned,
      size: { width: 500, height: 400 },
      position: { x: 400, y: 100 },
      resizable: true,
      children: <OptionsWindow />,
      z_index: 0,
      on_close: (window_id: string) => {
        destroy_window(window_id);
      }
    };

    create_window(window);
  };
};

export const useOptionsInit = () => {
  // Later this should attempt a login before pulling option data from local or remote storage

  const ls = useLocalStorage();
  const dispatch = useDispatch<StoreDispatch>();

  dispatch.desktop.set_eorzean_clock_visibility(
    ls.get<boolean>(keys.desktop.is_eorzean_clock_visible) ?? true
  );
  dispatch.desktop.set_local_clock_visibility(
    ls.get<boolean>(keys.desktop.is_local_clock_visible) ?? true
  );
  dispatch.desktop.set_server_clock_visibility(
    ls.get<boolean>(keys.desktop.is_server_clock_visible) ?? true
  );

  dispatch.roleplay.set_default_post_count_visibility(
    ls.get<boolean>(keys.roleplay.show_post_count) ?? true
  );
  dispatch.roleplay.set_default_post_count_template(
    ls.get<string>(keys.roleplay.post_count_template) ?? '[[c]]/[[t]]'
  );
  dispatch.roleplay.set_default_chat_channel(
    ls.get_object<ChatChannel>(keys.roleplay.default_chat_channel) ??
    new ChatChannel('Say', 's')
  );
  dispatch.roleplay.set_confirm_on_clear(
    ls.get<boolean>(keys.roleplay.confirm_on_clear) ?? false
  );
  dispatch.parser.set_max_post_length(
    ls.get<number>(keys.parser.max_post_length) ?? 475
  );
};

export const useEorzeanClockOption = (): [
  boolean,
  (value: boolean) => void
] => {
  const val = useSelector(
    (state: iRootState) => state.desktop.is_eorzean_clock_visible
  );
  const dispatch = useDispatch<StoreDispatch>();
  const local_storage = useLocalStorage();
  const set_val = (value: boolean) => {
    dispatch.desktop.set_eorzean_clock_visibility(value);
    local_storage.set(keys.desktop.is_eorzean_clock_visible, value);
  };
  return [val, set_val];
};

export const useLocalClockOption = (): [
  boolean,
  (value: boolean) => void
] => {
  const val = useSelector(
    (state: iRootState) => state.desktop.is_local_clock_visible
  );
  const dispatch = useDispatch<StoreDispatch>();
  const local_storage = useLocalStorage();
  const set_val = (value: boolean) => {
    dispatch.desktop.set_local_clock_visibility(value);
    local_storage.set(keys.desktop.is_local_clock_visible, value);
  };
  return [val, set_val];
};

export const useServerClockOption = (): [
  boolean,
  (value: boolean) => void
] => {
  const val = useSelector(
    (state: iRootState) => state.desktop.is_server_clock_visible
  );
  const dispatch = useDispatch<StoreDispatch>();
  const local_storage = useLocalStorage();
  const set_val = (value: boolean) => {
    dispatch.desktop.set_server_clock_visibility(value);
    local_storage.set(keys.desktop.is_server_clock_visible, value);
  };
  return [val, set_val];
};

export const usePostCountOption = (): [
  boolean,
  (value: boolean) => void
] => {
  const val = useSelector(
    (state: iRootState) => state.roleplay.show_post_count
  );
  const dispatch = useDispatch<StoreDispatch>();
  const local_storage = useLocalStorage();
  const set_val = (value: boolean) => {
    dispatch.roleplay.set_default_post_count_visibility(value);
    local_storage.set(keys.roleplay.show_post_count, value);
  };
  return [val, set_val];
};

export const usePostCountTemplateOption = (): [
  string,
  (value: string) => void
] => {
  const val = useSelector(
    (state: iRootState) => state.roleplay.post_count_template
  );
  const dispatch = useDispatch<StoreDispatch>();
  const local_storage = useLocalStorage();
  const set_val = (value: string) => {
    dispatch.roleplay.set_default_post_count_template(value);
    local_storage.set(keys.roleplay.post_count_template, value);
  };
  return [val, set_val];
};

export const useDefaultActiveChannel = (): [
  ChatChannel,
  (value: ChatChannel) => void
] => {
  const channel = useSelector(
    (state: iRootState) => state.roleplay.default_channel
  );
  const dispatch = useDispatch<StoreDispatch>();
  const local_storage = useLocalStorage();
  const set_channel = (
    value: ChatChannel
  ) => {
    dispatch.roleplay.set_default_chat_channel(value);
    local_storage.set(keys.roleplay.default_chat_channel, value);
  };
  return [channel, set_channel];
};

export const useMaxPostLength = (): [
  number,
  (value: number) => void
] => {
  const max_post_length = useSelector(
    (state: iRootState) => state.parser.max_post_length
  );
  const dispatch = useDispatch<StoreDispatch>();
  const local_storage = useLocalStorage();
  const set_max_post_length = (
    value: number
  ) => {
    dispatch.parser.set_max_post_length(value);
    local_storage.set(keys.parser.max_post_length, value);
  };
  return [max_post_length, set_max_post_length];
};

export const useConfirmOnClearOption = (): [
  boolean,
  (value: boolean) => void
] => {
  const val = useSelector(
    (state: iRootState) => state.roleplay.confirm_on_clear
  );
  const dispatch = useDispatch<StoreDispatch>();
  const local_storage = useLocalStorage();
  const set_val = (value: boolean) => {
    dispatch.roleplay.set_confirm_on_clear(value);
    local_storage.set(keys.roleplay.confirm_on_clear, value);
  };
  return [val, set_val];
};
