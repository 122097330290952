import * as React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  update_block: {
    padding: '10px'
  },
  header: {
    fontSize: 'large',
    fontWeight: 'bold'
  },
  subheader: {
    fontSize: 'large'
  },
  date: {
    fontSize: 'x-small'
  }
});

export interface Release0202Props {
  className?: string;
}

const Release0310 = ({ className }: Release0202Props) => {
  const styles = useStyles();

  return (
    <div>
      <div className={styles.update_block}>
        <hr />
        <div>
          <div className={styles.subheader}>
            3.1.0 <span className={styles.date}>(2024-12-14)</span>
          </div>
          A lot's happened over the past two and a half years. I was stricken with
          some rough and scary physical illnesses for most of it, but I'm finally
          back to almost 100%. Side projects fell away sadly and I haven't really
          done anything notable outside my job, but that's enough about me. What's
          important is that this site is still important to a (hopefully) good
          number of people. Maybe I can reboot my idea for Loredive in the future,
          but this site's still going nowhere.
          <br />
          <br />
          Keep living your best lives out there.
          <br />
          <br />
          Changes:
          <br />
          <ul>
            <li>
              <i>Added option to set the template used for a split post's post count.</i>
            </li>
          </ul>
          <br />
          <br />
          ~Auri &nbsp;
          <a href='mailto:auriana@ffxiv-toolkit.com'>
            &lt;auriana@ffxiv-toolkit.com&gt;
          </a>
        </div>
      </div>
    </div>
  );
};
export default Release0310;
