import { createUseStyles } from 'react-jss';

const UseFormStyles = createUseStyles({
  checkbox: {
    display: 'inline-block',
    height: '12px',
    width: '12px',
    boxSizing: 'border-box',
    border: '1px solid #111111c5',
    borderRadius: '1px',
    marginRight: '8px',
    marginBottom: '-1px'
  },
  unchecked: {
    boxShadow: '0px 0px 1px 0px #dddddd, inset 0px 0px 1px 0px #999290',
    background: 'linear-gradient(#343131, #999290)'
  },
  checked: {
    boxShadow: '0px 0px 1px 0px #fb5, inset 0px 0px 1px 0px black',
    background: 'radial-gradient(circle, #ffc034 0%, #ffc034 30%, #ffec7f 70%)'
  },
  textbox: {
    display: 'inline-block',
    font: {
      family: 'Calibri, Trebuchet MS, sans-serif'
    },
    background: '#272627',
    color: '#dddddd',
    border: {
      width: 0,
      radius: 4
    },
    boxShadow: 'inset -1px -1px 0.5px #ffffff22, inset 1px 1px 0.5px #00000022',
    resize: 'none',
    outline: '0 none',
    boxSizing: 'border-box',
    padding: 3
  },
  pad: {
    display: 'inline-block',
    width: '5px',
  },
  description: {
    marginTop: 5,
    marginBottom: 5
  }
});

export default UseFormStyles;
