const punctuators: string[] = ['.', ',', '!', '?'];

export class Parser {
  public channel: string = 's';
  private readonly max_post_length: number;
  private readonly post_count_template: string;

  private constructor(max_post_length: number, post_count_template: string) {
    this.max_post_length = max_post_length;
    this.post_count_template = post_count_template;
  }

  public static new(max_post_length: number, post_count_template: string) {
    return new Parser(max_post_length, post_count_template);
  }

  public parse_into_fragments(input: string): FragmentCollection {
    let is_quote_open = false;
    let retval: FragmentCollection = new FragmentCollection(
      this.max_post_length
    );

    for (let word of input.split(' ')) {
      if (contains_punctuator(word) || contains_quote(word)) {
        const word_ending = detect_if_ended(word, is_quote_open);
        if (is_quote_open !== word_ending.is_quote_open) {
          word_ending.is_quote_open
            ? retval.open_quote()
            : retval.close_quote();
        }
        is_quote_open = word_ending.is_quote_open;

        if (word_ending.is_ended) {
          retval.append_word(word_ending.ended_word);
          retval.commit_fragment();
          if (word_ending.new_word.length > 0) {
            retval.append_word(word_ending.new_word);
          }
          continue;
        }
      }
      retval.append_word(word);
    }
    retval.seal();
    return retval;
  }

  public generate_posts(input: string): PostCollection {
    let retval: PostCollection = new PostCollection(
      this.channel,
      this.max_post_length,
      this.post_count_template
    );
    const lines = input.split('\n');
    for (let line of lines) {
      line = line.trim();
      if (line.length > 0) {
        const fragment_collection = this.parse_into_fragments(line);
        retval.generate_and_append_posts(fragment_collection);
      }
    }

    return retval;
  }

  public parse_into_posts(
    default_channel: string,
    input: string
  ): PostCollection {
    let retval: PostCollection = new PostCollection(
      default_channel,
      this.max_post_length,
      this.post_count_template
    );
    const lines = input.split('\n');
    for (let line of lines) {
      line = line.trim();
      if (line.length > 0) {
        const fragment_collection = this.parse_into_fragments(line);
        retval.generate_and_append_posts(fragment_collection);
      }
    }

    return retval;
  }

  public get_word_count(input: string): number {
    let retval = 0;
    for (let paragraph of input.split('\n')) {
      for (let word of paragraph.split(' ')) {
        if (word.length > 0) {
          retval++;
        }
      }
    }
    return retval;
  }
}

export class PostCollection {
  public posts: Post[];
  private readonly default_channel: string;
  private current_post: Post;
  private readonly max_post_length: number;
  private readonly post_count_template: string;

  constructor(default_channel: string, max_post_length: number, post_count_template: string) {
    this.posts = [];
    this.default_channel = default_channel;
    this.current_post = new Post(this.default_channel);
    this.max_post_length = max_post_length;
    this.post_count_template = post_count_template;
  }

  private commit_post() {
    if (
      this.current_post.is_open_quote_at_end &&
      this.current_post.body.slice(-1) !== '"'
    ) {
      this.current_post.add_closing_quote();
    }
    this.posts.push(this.current_post);
    this.current_post = new Post(this.default_channel);
  }

  private append_fragment(fragment: Fragment) {
    if (this.current_post.length + fragment.length > this.max_post_length) {
      this.commit_post();
    }
    this.current_post.append(fragment);
  }

  private set_post_numbers() {
    for (let i = 0; i < this.posts.length; i++) {
      let post = this.posts[i];
      post.post_number = this.post_count_template
        .replace('[[c]]', (i + 1).toString())
        .replace('[[t]]', this.posts.length.toString());
      post.is_partial = this.posts.length > 1;
    }
  }

  public generate_and_append_posts(fragment_collection: FragmentCollection) {
    let is_quote_open = false;
    for (let i = 0; i < fragment_collection.fragments.length; i++) {
      const fragment = fragment_collection.fragments[i];

      if (fragment.is_quote && !is_quote_open && fragment.is_open) {
        const quote_length = detect_quote_length(
          fragment_collection.fragments,
          i
        );

        if (
          this.current_post.length + quote_length >= this.max_post_length &&
          quote_length <= this.max_post_length
        ) {
          this.commit_post();
        }
      }

      is_quote_open = fragment.is_open;
      this.append_fragment(fragment);
    }
    this.commit_post();
    this.set_post_numbers();
  }
}

export class Post {
  public channel_tag: string;
  public body: string;
  public is_copied: boolean;
  public length: number;
  public is_open_quote_at_end: boolean;
  public is_partial: boolean;
  public post_number: string;

  constructor(channel_tag: string, body?: string | null, is_copied?: boolean) {
    this.channel_tag = channel_tag;
    this.body = body ?? '';
    this.is_copied = is_copied ?? false;
    this.length = this.body.length;
    this.is_open_quote_at_end = false;
    this.is_partial = false;
    this.post_number = '';
  }

  public get full_text(): string {
    return `/${this.channel_tag} ${this.body}`;
  }

  public append(fragment: Fragment) {
    let prefix = '';
    if (
      this.body.length === 0 &&
      fragment.is_quote &&
      fragment.is_open &&
      fragment.full_content.slice(0, 1) !== '"'
    ) {
      prefix = '"';
    }
    this.body = `${this.body} ${prefix}${fragment.full_content}`.trim();
    this.length = this.body.length;
    this.is_open_quote_at_end = fragment.is_open;
  }

  public add_closing_quote() {
    this.body += '"';
    this.length++;
  }
}

interface WordEnding {
  is_ended: boolean;
  is_quote_open: boolean;
  ended_word: string;
  new_word: string;
}

class FragmentCollection {
  public fragments: Fragment[];
  private current_fragment: Fragment;
  private is_sealed: boolean;
  private max_post_length: number;

  constructor(max_post_length: number) {
    this.fragments = [];
    this.current_fragment = new Fragment();
    this.is_sealed = false;
    this.max_post_length = max_post_length;
  }

  public commit_fragment() {
    if (!this.is_sealed) {
      this.fragments.push(this.current_fragment);
      const committed_fragment = this.current_fragment;
      this.current_fragment = new Fragment();
      if (committed_fragment.is_open) {
        this.current_fragment.is_quote = true;
        this.current_fragment.is_open = true;
      }
    }
  }

  public append_word(word: string) {
    if (
      !this.is_sealed &&
      word.length > 0 &&
      !this.current_fragment.append_word(word, this.max_post_length)
    ) {
      this.commit_fragment();
      this.current_fragment.append_word(word, this.max_post_length);
    }
  }

  public open_quote() {
    this.current_fragment.is_quote = true;
    this.current_fragment.is_open = true;
  }

  public close_quote() {
    this.current_fragment.is_open = false;
  }

  public seal() {
    if (this.current_fragment.length > 0) {
      this.commit_fragment();
    }
    let last_fragment = this.fragments[this.fragments.length - 1];
    if (last_fragment.is_quote && last_fragment.is_open) {
      last_fragment.content[last_fragment.content.length - 1] += '"';
      last_fragment.is_open = false;
    }
    this.is_sealed = true;
  }
}

export class Fragment {
  content: string[];
  is_quote: boolean;
  is_open: boolean;
  length: number;

  constructor(word?: string) {
    this.content = word ? [word] : [];
    this.is_open = false;
    this.is_quote = false;
    this.length = 0;
  }

  public append_word(word: string, max_post_length: number): boolean {
    if (this.length + word.length >= max_post_length) {
      return false;
    }
    this.content.push(word);
    this.length += word.length + 1;
    if (this.content.length === 1) {
      this.length -= 1;
    }
    return true;
  }

  public get full_content(): string {
    return this.content.join(' ');
  }
}

export const detect_quote_length = (
  fragments: Fragment[],
  start_index: number
): number => {
  let retval = 0;
  let fragment_count = 0;
  for (let index = start_index; index < fragments.length; index++) {
    const fragment = fragments[index];
    fragment_count++;
    retval += fragment.length;
    if (fragment_count > 1) {
      retval++;
    }
    if (!fragment.is_open) {
      break;
    }
  }
  return retval;
};

function contains_punctuator(content: string): boolean {
  return punctuators.some(punctuator => content.includes(punctuator));
}

function is_punctuator(character: string): boolean {
  return punctuators.includes(character);
}

const contains_quote = (content: string): boolean => {
  return content.includes('"');
};

const is_quote = (character: string): boolean => {
  return character === '"';
};

type SplitWordType = { ended_word: string; new_word: string };

export function split_word_at_index(
  word: string,
  index: number
): SplitWordType {
  const first_word = word.substring(0, index + 1);
  const last_word = word.substring(index + 1);
  return { ended_word: first_word, new_word: last_word };
}

export function is_word_ended_by_punctuator(
  char: string,
  next_char: string | undefined,
  is_quote_open: boolean
): boolean {
  return (
    is_punctuator(char) &&
    (!next_char || !is_quote_open || !is_quote(next_char ?? ''))
  );
}

export function detect_if_ended(
  word: string,
  is_quote_open: boolean
): WordEnding {
  let is_ended = false;
  let ended_word = '';
  let new_word = '';

  for (let index = 0; index < word.length; index++) {
    const char = word.charAt(index);
    const next_char =
      index !== word.length - 1 ? word.charAt(index + 1) : undefined;

    let index_to_split: number | undefined = undefined;

    if (
      !is_ended &&
      is_punctuator(char) &&
      is_word_ended_by_punctuator(char, next_char, is_quote_open)
    ) {
      index_to_split = index;
    } else if (is_quote(char)) {
      if (is_quote_open) {
        index_to_split = index;
      }
      is_quote_open = !is_quote_open;
    }

    if (index_to_split !== undefined) {
      is_ended = true;
      const split_word = split_word_at_index(word, index);
      ended_word = split_word.ended_word;
      new_word = split_word.new_word;
    }
  }
  return {
    is_ended,
    is_quote_open,
    ended_word,
    new_word
  };
}

const author = {
  parser: {
    new: (max_post_length: number): Parser => {
      return Parser.new(max_post_length, '[[c]]/[[t]]');
    }
  }
};
export default author;
